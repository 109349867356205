import '../styles/TasksList.css'
import React, { useEffect, useState } from 'react';

const CustomSelect = ({ name, options, onFilterChange }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOptions, setSelectedOptions] = useState(new Set());
    const [item, setItem] = useState(options);
  
    const toggleDropdown = () => {
        setItem(options)
        setIsOpen(!isOpen);
    };
  
    const handleCheckboxChange = (option) => {
        const newSelectedOptions = new Set(selectedOptions);
        if (newSelectedOptions.has(option)) {
            newSelectedOptions.delete(option);
        } else {
            newSelectedOptions.add(option);
        }
        setSelectedOptions(newSelectedOptions);
        onFilterChange(Array.from(newSelectedOptions));
    };
  
    const handleSearchChange = (e) => {
        if (name === 'performer') {
          if (e.target.value === '') {
            setItem(options);
            setSearchTerm(e.target.value);
          }
          else {
            setSearchTerm(e.target.value);
            const regex = new RegExp(e.target.value, "i");
            const filtered = options.filter((item) => regex.test(item.username));
            setItem(filtered);
          }
        }
        else if (name === 'label') {
          if (e.target.value === '') {
            setItem(options);
            setSearchTerm(e.target.value);
          }
          else {
            setSearchTerm(e.target.value);
            const regex = new RegExp(e.target.value, "i");
            const filtered = options.filter((item) => regex.test(item.name));
            setItem(filtered);
          }
        }
        else if (name === 'status') {
          if (e.target.value === '') {
            setItem(options);
            setSearchTerm(e.target.value);
          }
          else {
            setSearchTerm(e.target.value);
            const regex = new RegExp(e.target.value, "i");
            const filtered = options.filter((item) => regex.test(item.title));
            setItem(filtered);
          }
        }
        else if (name === 'priority') {
          if (e.target.value === '') {
            setItem(options);
            setSearchTerm(e.target.value);
          }
          else {
            setSearchTerm(e.target.value);
            const regex = new RegExp(e.target.value, "i");
            const filtered = options.filter((item) => regex.test(item));
            setItem(filtered);
          }
        }
    };

    const filteredOptions = options.includes(searchTerm);
  
    return (
      <div>
        {name === 'performer' ?
        <div id="button-open-custom-filter" onClick={toggleDropdown}>
          Исполнитель
          <label id="toggle">
            &#9660;
          </label>
        </div>:
        name === 'label' ?
        <div id="button-open-custom-filter" onClick={toggleDropdown}>
          Метка
          <label id="toggle">
            &#9660;
          </label>
        </div>:
        name === 'status' ?
        <div id="button-open-custom-filter" onClick={toggleDropdown}>
          Группа
        <label id="toggle">
          &#9660;
        </label>
        </div>:
        <div id="button-open-custom-filter-priority" onClick={toggleDropdown}>
            &#9733;
          <label id="toggle">
            &#9660;
          </label>
        </div>
        }
        {isOpen && (
          <div style={{ border: 'none', position: 'absolute', backgroundColor: 'white', boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.25)", borderRadius: '10px', zIndex: "2" }}>
            {name === 'performer' || 'status' || 'label' ?
            <input 
              id="search-filter-custom-select"
              type="text"
              placeholder="Поиск"
              value={searchTerm}
              onChange={handleSearchChange}
            />: null
}
            {name === 'performer' ?
            <div>
              {item.map((option, index) => (
                <div id="filter-div-select">
                {option.formated_fio ?
                  <label id="custom-filter-label" key={index}>{option.formated_fio}</label>:
                  <label id="custom-filter-label" key={index}>{option.username}</label>
                }
                <input id="custom-check-box"
                    type="checkbox"
                    checked={selectedOptions.has(option)}
                    onChange={() => handleCheckboxChange(option)}
                />
                </div>
              ))}
            </div>:
            name === 'label' ?
            <div>
              {item.map((option, index) => (
                <div id="filter-div-select">
                <label id="custom-filter-label" key={index}>{option.name}</label>
                <input id="custom-check-box"
                    type="checkbox"
                    checked={selectedOptions.has(option)}
                    onChange={() => handleCheckboxChange(option)}
                />
                </div>
              ))}
            </div>:
            name === 'status' ?
            <div>
            {item.map((option, index) => (
              <div id="filter-div-select">
              <label id="custom-filter-label" key={index}>{option.title}</label>
              <input id="custom-check-box"
                  type="checkbox"
                  checked={selectedOptions.has(option)}
                  onChange={() => handleCheckboxChange(option)}
              />
              </div>
            ))}
          </div>:
          <div>
          {item.map((option, index) => (
            <div id="filter-div-select">
            <label id="custom-filter-label" key={index}>{option}</label>
            <input id="custom-check-box"
                type="checkbox"
                checked={selectedOptions.has(option)}
                onChange={() => handleCheckboxChange(option)}
            />
            </div>
          ))}
        </div>
          }
          </div>
        )}
      </div>
    );
};

export default CustomSelect;