import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './styles/Header.css'
// import TelegramLoginButton from 'react-telegram-login';
// import axios from 'axios';
import api from '/app/frontend/src/api'

const Header = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    // const telegramBotName = "SmarTaskBot";
    const location = useLocation();
    const [user, setUser] = useState('');
    const token = localStorage.getItem('token');
    const [boardname, setBoardName] = useState('');
    const [numberoftasks, setTasksnumber] = useState([]);
    const [author, setauthor] = useState('');
    const [checkRole, setCheckRole] = useState(false);
    const [checkPermission, setCheckPermission] = useState(false);
    const [addRole, setAddRole] = useState(false);

    // const handleTelegramResponse = response => {
    //     axios.get(`${apiUrl}api/`, {
    //     params:response
    //   }).then(response => {
    //     console.log(response.data)
    //     if (response.status === 200){
    //       const tokenJson = response.data;
    //       if (tokenJson.access_token && tokenJson.refresh_token) {
    //         localStorage.setItem('token', tokenJson.access_token);
    //         localStorage.setItem('refreshToken', tokenJson.refresh_token);
    //         window.location.href = "/choice-organization"
    //       }
    //       else {
    //         alert("Ваш аккаунт не найден, обратитесь к администратору вашей организации.");  
    //       }
    //     }
    //     else{
    //       alert("Ваш аккаунт не найден, обратитесь к администратору вашей организации.");
    //     }
    //   }).catch(error => {
    //     const status = error.response ? error.response.status : null;
    //     alert(`Ошибка: ${error.code}, Код ошибки: ${status}`, error);
    //   });
    // };

    const handleGoToBoards = (event) => {
        window.location.href = "/main";
    };
    const handleGoToBoardsNewPin = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        window.open("/main");
      }
    };
    const handleGoToAuth = (event) => {
        window.location.href = "/login";
    };
    const handleGoToProfile = (event) => {
        window.location.href = "/profile/" + user.username;
    };
    const handleGoToProfileNewPin = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        window.open("/profile/" + user.username);
      }
    };
    const handleGoToRevertBoard = (event) => {
        const slug_boards = localStorage.getItem('slugboards');
        window.location.href = `/boards/${slug_boards}`;
    };
    const handleGoToRevertBoardNewPin = (e) => {
      if (e.button === 1) {
        const slug_boards = localStorage.getItem('slugboards');
        window.open(`/boards/${slug_boards}`);
      }
  };
    const handleGoToTask = (event) => {
        window.location.href = "/tasks/list/";
    };
    const handleGoToTaskNewPin = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        window.open("/tasks/list/");
      }
    };
    const handleGoToUnauth = () => {
        localStorage.setItem('token', '');
        localStorage.setItem('refreshToken', '');
        window.location.href = '/login';
    };

    function getnumbertask() {
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        return (<label id="boardname">Задача {task_pk}</label>);
    };

    const handleGoToSettings = (event) => {
      window.location.href = "/settings"
    };
    const handleGoToSettingsNewPin = (e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/settings");
        }
    };
  
    function CheckAuth() {
        if (user !== '') {
            if (location.pathname.startsWith(`/profile/${user.username}`))
            {
              return(<button onClick={handleGoToUnauth} id="redirectProfile">Выйти</button>);
            }
            else if (location.pathname === '/')
            {
              return(<button onClick={() => window.location.href = '/main'} id="redirectProfile">{user.formated_fio ? user.formated_fio: user.username}</button>);
            }
            else {
              return(<button onMouseDown={(e) => handleGoToProfileNewPin(e)} onClick={handleGoToProfile} id="redirectProfile">{user.formated_fio ? user.formated_fio: user.username}</button>);
            }
        }
      else {
        return(<button onClick={handleGoToAuth} id="redirectLogin">Войти</button>);
      }
    };

    const handleGoToTechnicalSupport = (event) => {
      window.location.href = "/main-info"  
    };
    const handleGoToTechnicalSupportNewPin = (e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/main-info");
        }
    };

    React.useEffect(() => {
      const CheckPermission = async () => {
        await api.get(`${apiUrl}api/v1/user/permissions/`, {
          headers: {
              'Authorization': 'Bearer ' + token
          },
        }).then((response) => {
          if (response.status === 200) {
              // if (response.data.includes('Can view Задача')) {
              //   setCanSeeTask(true);
              // }
              // else{
              //   if (location.pathname.startsWith('/tasks/list')
              //     //  || location.pathname.startsWith('/task/')
              //     ){
              //     window.location.href = '/main'
              //   }
              // }
              if (location.pathname.startsWith('/create-task')){
                if (response.data.includes('owner_organization') || response.data.includes('Can add Задача')) {
                }
                else{
                  window.location.href = '/main'
                }
              }
              if (location.pathname.startsWith('/settings')){
                if (response.data.includes('owner_organization') || response.data.includes('Can view group') || (response.data.includes('Can add group') && response.data.includes('Can view permission'))) {
                }
                else{
                  window.location.href = '/main'
                }
              }
              if (location.pathname.startsWith('/main')){
                if (response.data.includes('owner_organization'))
                {
                  setCheckRole(true);
                }
                if (response.data.includes('Can view permission') ){
                  setCheckPermission(true);
                }
                if (response.data.includes('Can add group')){
                  setAddRole(true);
                }
              }
          }
      }).catch(error => {
          console.log(error);
      });
      }
        if (location.pathname.startsWith('/boards/')) {
          let checkboardsslug = window.location.pathname.split('/', 3);
          api.get(`${apiUrl}api/v1/boards/${checkboardsslug[2]}/`, {
              headers: {
                  'Authorization': 'Bearer ' + token
              },
          }).then((response) => {
              if (response.status === 200) {
                setBoardName(response.data.title);
                setauthor(response.data.author);
                localStorage.setItem('titleboards', response.data.title);
                localStorage.setItem('slugboards', response.data.slug);
                localStorage.setItem('boardID', response.data.id);
          }});
        }
          api.get(`${apiUrl}api/identity/auth/users/me/`, {
              headers: {
                  'Authorization': 'Bearer ' + token
              },
          }).then((response) => {
            if (response.status === 200) {
              setUser(response.data);
              if (response.data.username === author) {
                localStorage.setItem('author', author);
              }
              localStorage.setItem('username', response.data.username);
            }
          }).catch(error =>{
            console.error(error);
          });
        if (location.pathname.startsWith('/tasks/')) {
          api.get(`${apiUrl}api/v1/tasks/`, {
              headers: {
                  'Authorization': 'Bearer ' + token
              },
          }).then((response) => {
            if (response.status === 200) {
              const task = response.data;
              setTasksnumber(task.length);
            }
          }).catch(error =>{
              if (error.response.status === 403){
                 window.location.href = '/main';
            }
      });
    }
    CheckPermission();
    // eslint-disable-next-line
  }, []);

  if (location.pathname==='/login' || location.pathname==='/' 
  || location.pathname==='' || location.pathname==='/create-appeal' 
  || location.pathname==='/about' || location.pathname==='/privacy-policy'
  || location.pathname==='/developers-info/' || location.pathname==='/developers-info'
  || location.pathname==='/create-appeal/' 
  || location.pathname==='/about/' || location.pathname==='/privacy-policy/'
  || location.pathname==='/prices-info' || location.pathname==='/prices-info/'
  || location.pathname==='/solutions-info' || location.pathname==='/solutions-info/'
  || location.pathname==='/registration' || location.pathname==='/registration/') {
    return (
      <header className="header">
            <div id="companyName">
              <img id="logo-gif"
                src={apiUrlFrontend + 'logo.gif'}
                alt=''
              >
              </img>
              <Link onClick={() => window.location.href= '/'} relative="path" id="project-name">Твоя</Link>
              <Link onClick={() => window.location.href= '/'} relative="path" id="companyNameT">З</Link>
              <Link onClick={() => window.location.href= '/'} relative="path" id="project-nameAsk">адача</Link>
            </div>
            <div id="div-with-circle">
              { location.pathname==='/developers-info' || location.pathname==='/developers-info/' ? 
                <Link style={{fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/developers-info'} id="developers">Разработчики</Link>: 
                <Link onClick={() => window.location.href= '/developers-info'} id="developers">Разработчики</Link>
              }
              <div className="circle"></div>
              { location.pathname==='/solutions-info' || location.pathname==='/solutions-info/' ?
                <Link style={{fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/solutions-info'} id="solutions">Решения</Link>: 
                <Link onClick={() => window.location.href= '/solutions-info'} id="solutions">Решения</Link>
              } 
              <div className="circle"></div>
              { location.pathname==='/prices-info' || location.pathname==='/prices-info/' ?
                <Link style={{fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/prices-info'} id="prices">Цены</Link>: 
                <Link onClick={() => window.location.href= '/prices-info'} id="prices">Цены</Link>
              }
            </div>
            <div className="divbtn">
              {/* <div id="div-tg-btn">
                <TelegramLoginButton id="tgbutton" dataOnauth={handleTelegramResponse} botName={telegramBotName}/>
              </div> */}
              {CheckAuth()}
            </div>
      </header>
    );
  } 
  else if (location.pathname==='/main' ||
     location.pathname.startsWith('/profile/') || 
     location.pathname==='/create-task' || 
     location.pathname==='/settings'
     || location.pathname==='/main-info' || location.pathname==='/main-info/') {
    return(
    <header className="header">
          <div id="companyName">
              <img id="logo-gif"
                src={apiUrlFrontend + 'logo.gif'}
                alt=''
              >
              </img>
              <Link onClick={() => window.location.href= '/main'} relative="path" id="project-name">Твоя</Link>
              <Link onClick={() => window.location.href= '/main'} relative="path" id="companyNameT">З</Link>
              <Link onClick={() => window.location.href= '/main'} relative="path" id="project-nameAsk">адача</Link>
          </div>
          <div className="divbtn">
            {location.pathname!=='/main' ?
            <button onMouseDown={(e) => handleGoToBoardsNewPin(e)} onClick={handleGoToBoards} id="openActiveBoards">Активные доски
            </button>: null}
              <button onMouseDown={(e) => handleGoToTaskNewPin(e)} onClick={handleGoToTask} id="openActiveTasks">Задачи</button>
              {checkRole === true || (checkPermission === true && addRole === true) ?
                <div>
                    <button 
                        onMouseDown={(e) => handleGoToSettingsNewPin(e)}
                        onClick={(event) => handleGoToSettings(event)} 
                        id="settings"
                    >
                        Настройки</button>
                    <div id="enter"></div>
                </div>: null
              }
              {location.pathname!=='/main-info' ?
              <button id="go-to-main-info-button" onMouseDown={(e) => handleGoToTechnicalSupportNewPin(e)} onClick={(event) => handleGoToTechnicalSupport(event)}>Обзор задач</button>: null}
            {CheckAuth()}
          </div>
    </header>
    );
  }
  else if(location.pathname.startsWith('/boards/')) {
    return(
      <header className="header">
          <div id="companyName">
              <img id="logo-gif"
                src={apiUrlFrontend + 'logo.gif'}
                alt=''
              >
              </img>
              <Link onClick={() => window.location.href= '/main'} relative="path" id="project-name">Твоя</Link>
              <Link onClick={() => window.location.href= '/main'} relative="path" id="companyNameT">З</Link>
              <Link onClick={() => window.location.href= '/main'} relative="path" id="project-nameAsk">адача</Link>
              <div id="divlinevertical"></div>
              <label id="boardname">{boardname}</label>
            </div>
            <div className="divbtn">
              <button onClick={handleGoToBoards} onMouseDown={(e) => handleGoToBoardsNewPin(e)} id="openActiveBoards">Активные доски
              </button>
              <button onMouseDown={(e) => handleGoToTaskNewPin(e)} onClick={handleGoToTask} id="openActiveTasks">Задачи
              </button>
              <button id="go-to-main-info-button" onMouseDown={(e) => handleGoToTechnicalSupportNewPin(e)} onClick={(event) => handleGoToTechnicalSupport(event)}>Обзор задач</button>
              {CheckAuth()}
            </div>
      </header>
      );
  }
  else if(location.pathname === '/tasks/list/') {
    return(
      <header className="header">
          <div id="companyName">
              <img id="logo-gif"
                src={apiUrlFrontend + 'logo.gif'}
                alt=''
              >
              </img>
              <Link onClick={() => window.location.href= '/main'} relative="path" id="project-name">Твоя</Link>
              <Link onClick={() => window.location.href= '/main'} relative="path" id="companyNameT">З</Link>
              <Link onClick={() => window.location.href= '/main'} relative="path" id="project-nameAsk">адача</Link>
              <div id="divlinevertical"></div>
              <label id="boardname">{numberoftasks}</label>
          </div>
          <div className="divbtn">
            <button onClick={handleGoToBoards} onMouseDown={(e) => handleGoToBoardsNewPin(e)} id="openActiveBoards">Активные доски
            </button>
            <button id="go-to-main-info-button" onMouseDown={(e) => handleGoToTechnicalSupportNewPin(e)} onClick={(event) => handleGoToTechnicalSupport(event)}>Обзор задач</button>
            {CheckAuth()}
          </div>
      </header>
    );
  }
  else if(location.pathname.startsWith('/task/')) {
    return(
      <header className="header">
            <div id="companyName">
              <img id="logo-gif"
                src={apiUrlFrontend + 'logo.gif'}
                alt=''
              >
              </img>
              <Link onClick={() => window.location.href= '/main'} relative="path" id="project-name">Твоя</Link>
              <Link onClick={() => window.location.href= '/main'} relative="path" id="companyNameT">З</Link>
              <Link onClick={() => window.location.href= '/main'} relative="path" id="project-nameAsk">адача</Link>
              <div id="divlinevertical"></div>
              {getnumbertask()}
            </div>
            <div className="divbtn">
            <button onClick={handleGoToBoards} onMouseDown={(e) => handleGoToBoardsNewPin(e)} id="openActiveBoards">Активные доски
            </button>
              <button onMouseDown={(e) => handleGoToTaskNewPin(e)} onClick={handleGoToTask} id="openActiveTasks">Задачи
              </button>
              <button id="go-to-main-info-button" onMouseDown={(e) => handleGoToTechnicalSupportNewPin(e)} onClick={(event) => handleGoToTechnicalSupport(event)}>Обзор задач</button>
              <button onClick={handleGoToRevertBoard} onMouseDown={(e) => handleGoToRevertBoardNewPin(e)} id="openActiveTasks">Вернуться на доску
              </button>
              {CheckAuth()}
            </div>
      </header>
    );
  }
  else if (location.pathname.startsWith('/change-password/')) {
    return(
    <header className="header">
    <div id="companyName">
      <img id="logo-gif"
        src={apiUrlFrontend + 'logo.gif'}
        alt=''
      >
      </img>
      <Link onClick={() => window.location.href= '/'} relative="path" id="project-name">Твоя</Link>
      <Link onClick={() => window.location.href= '/'} relative="path" id="companyNameT">З</Link>
      <Link onClick={() => window.location.href= '/'} relative="path" id="project-nameAsk">адача</Link>
    </div>
    <div id="div-with-circle">
      { location.pathname==='/developers-info' || location.pathname==='/developers-info/' ? 
        <Link style={{fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/developers-info'} id="developers">Разработчики</Link>: 
        <Link onClick={() => window.location.href= '/developers-info'} id="developers">Разработчики</Link>
      }
      <div className="circle"></div>
      { location.pathname==='/solutions-info' || location.pathname==='/solutions-info/' ?
        <Link style={{fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/solutions-info'} id="solutions">Решения</Link>: 
        <Link onClick={() => window.location.href= '/solutions-info'} id="solutions">Решения</Link>
      } 
      <div className="circle"></div>
      { location.pathname==='/prices-info' || location.pathname==='/prices-info/' ?
        <Link style={{fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/prices-info'} id="prices">Цены</Link>: 
        <Link onClick={() => window.location.href= '/prices-info'} id="prices">Цены</Link>
      }
    </div>
    <div className="divbtn">
    </div>
</header>
    )
  }
  else if (location.pathname.startsWith('/choice-organization')){
    return (
      <header className="header">
      </header>
    )
  }
  else {
    return(
      <h1>А все теперь без хедера, сиди с футером только</h1>
    );
  }
}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
export default Header;