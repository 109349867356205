import React, { useState, useEffect } from 'react';
import './styles/DetailBoard.css';
import Modal from 'react-modal';
import api from '/app/frontend/src/api';
import { Tooltip } from 'react-tooltip';
import { format } from 'date-fns';

const DetailBoard = () => {
    const [boardid, setboardid] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const [group, setGroup] = useState([]);
    const [currentgroup, setCurrentGroup] = useState([]);
    const [task, setTask] = useState([]);
    const [isTextSelected, setIsTextSelected] = useState(false);
    const [modalIsOpen3, setIsOpen3] = useState(false);
    const [modalIsOpenGroup, setIsOpenGroup] = useState(false);
    const [performer, setperformer] = useState([]);
    const [titlegroup, settitlegroup] = useState('');
    const [column, setColumnChange] = useState('');
    const [currenttask, setCurrenttask] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [canViewGroup, setCanViewGroup] = useState(false);
    const [canViewTasks, setCanViewTasks] = useState(false);
    const [canAddTask, setCanAddTask] = useState(false);
    const [canAddGroup, setCanAddGroup] = useState(false);
    const [canChangeGroup, setCanChangeGroup] = useState(false);
    const [sortedDateTask, setSortedDateTask] = useState(false);
    let [taskfortask, setTaskForTask] = useState([]);
    const [systemStatus, setSystemStatus] = useState([]);
    const [selectedSystemStatus, setSelectedSystemStatus] = useState([]);
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const getboardid = async () => {
        await api.get(`${apiUrl}api/v1/boards/${window.location.pathname.replace(/\/$/, '').split('/').pop()}`,{
            headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setboardid(response.data.id);
                }
            }).catch(error => {
                if (error.response.status === 403) {
                    alert("Доска не найдена");
                    window.location.href = "/main";
                }
            });
    }
    const handlecreategroup = () => {
        setIsOpen3(true);
        api.get(`${apiUrl}api/v1/system-status/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200) {
                setSystemStatus(response.data);
                setSelectedSystemStatus(response.data
                    .filter(it => it.name === 'IsOpen')
                    .map(it => it.id).toString());
            }
        }).catch(error => {
            const status = error.response ? error.response.status: null;
            if (error.response.status === 403)
            {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
        });
    };

    const handleChangeSelectSystemStatus = (event) => {
        var select = window.confirm("При смене статуса состояния, все задачи в текущем состоянии примут новый статус состояния. Продолжить?");
        if (select) {
            setSelectedSystemStatus(event.target.value);
            api.patch(`${apiUrl}api/v1/groups/${selectedGroup.groupid}/update-system-status/`, {
                'system_status': event.target.value
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
            ).then((response) => {
                if (response.status === 200) {
                    closeModal();
                    updateall();
                }
            })
            .catch(error =>{
                console.error(error);   
            });
        }
        else {

        }
    };

    const handleChangeSelectSystemStatusForNewGroup = (event) => {
        setSelectedSystemStatus(event.target.value);
    };

    const handleSubmitCreateGroup = (event) => {
        event.preventDefault();
        if (group.some((group => group.title === column && group.board_id === boardid))) {
            alert('Группа с таким названием уже существует');
        }
        else if (!selectedSystemStatus){
            alert("Выберите статус состояния");
        }
        else {
            api.post(`${apiUrl}api/v1/groups/`, {
                'title': column,
                'board_id': boardid,
                'system_status': selectedSystemStatus,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
            ).then((response) => {
                if (response.status === 201) {
                    updateall();
                    closeModal();
                }
            })
            .catch(error =>{
                console.error(error);   
            });
        }
    };

    function handleOpendetailTasks(data) {
        window.location.href = `/task/${data.taskid}`;
    };

    function handleOpendetailTasksNewPin(e, data) {
        if (e.button === 1) {
            e.preventDefault();
            window.open(`/task/${data.taskid}`);
        }
    };

    const handledColumnChange = (event) => {
        if (event.target.value.length <= 50) {
            setColumnChange(event.target.value);
        } else {
            return;
        }
    };

    function closeModal() {
        setIsOpen3(false);
        setIsOpenGroup(false);
    };

    function openModal(data) {
        localStorage.setItem('grouptaskcreate', data.grouptitle);
        localStorage.setItem('grouptasksystemstatus', data.groupsystem_status);
        localStorage.setItem('grouptaskidcreate', data.group_id);
        localStorage.setItem('relatedtaskid', '')
        window.location.href = "/create-task"
    };

    const openModalNewPin = (e, data) => {
        if (e.button === 1) {
            e.preventDefault();
            localStorage.setItem('grouptaskcreate', data.grouptitle);
            localStorage.setItem('grouptaskidcreate', data.group_id);
            localStorage.setItem('relatedtaskid', '')
            window.open("/create-task")
        }
    };

    const handleTextareachange = (event) => {
        settitlegroup(event.target.value);
    };

    const handleTextareaClick = (event) => {
        const textarea = event.target;
        if (!isTextSelected){
            textarea.focus();
            textarea.setSelectionRange(0, textarea.value.length);
            setIsTextSelected(true);
        }
        else{
            setIsTextSelected(false);
        }
    };

    const handleTextAreaBlur = (data) => {
        setIsTextSelected(false);
        if (titlegroup && data.title !== titlegroup) {
            const group_id = data.group_id;
            api.patch(`${apiUrl}api/v1/groups/${group_id}/`, {
                'title': titlegroup,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    updateall();
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleDeleteGroup = () => {
        const result = window.confirm("ты подумай сейчас ок нажмешь и все удалится");
        if (result) {
            const group_id = selectedGroup.groupid;
            api.delete(`${apiUrl}api/v1/groups/${group_id}/`,{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 204) {
                    closeModal();
                    window.location.href = window.location;
                }
            }).catch(error =>{
                console.error(error);
            });
        }
    };

    const handleopenaccessdeletegroup = (data) => {
        if (canChangeGroup) {
            setSelectedSystemStatus(data.system_status)
            setSelectedGroup(data);
            setIsOpenGroup(true);
            api.get(`${apiUrl}api/v1/system-status/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                if (response.status === 200) {
                    setSystemStatus(response.data);
                }
            }).catch(error => {
                const status = error.response ? error.response.status: null;
                if (error.response.status === 403)
                {
                    alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
                }
            });
        }
        else {
            alert("Недостаточно прав для изменения статуса, обратитесь к администратору");
        }
    };

    function updateall() {
        getboardid();
        const CheckPermission = async () => {
            await api.get(`${apiUrl}api/v1/user/permissions/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    if (response.data.includes('Can view Доска')) {
                    }
                    else {
                        if (localStorage.getItem('author')) {

                        }
                        else {
                            window.location.href = '/main';
                        }
                    }
                    if (response.data.includes('Can add Задача')) {
                        setCanAddTask(true);
                    }
                    if (response.data.includes('Can add Группа')) {
                        setCanAddGroup(true);
                    }
                    if (response.data.includes('Can change Группа')) {
                        setCanChangeGroup(true);
                    }
                    if (response.data.includes('owner_organization')) {
                        setCanAddGroup(true);
                        setCanAddTask(true);
                        setCanChangeGroup(true);
                    }
                    if (localStorage.getItem('author')){
                        setCanAddGroup(true);
                        setCanAddTask(true);
                        setCanChangeGroup(true);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        };
        CheckPermission();
            api.get(`${apiUrl}api/v1/tasks/`,{
                params: {
                    'slugboard': window.location.pathname.replace(/\/$/, '').split('/').pop()
                },
                headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        setCanViewTasks(true);
                        const filterfortasks = (response.data.sort((a, b) => a.task_id - b.task_id));
                        setTask(filterfortasks);
                    }
                }).catch(error => {
                    if (error.response.status === 403)
                    {
                        // alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
                    }
                });
                api.get(`${apiUrl}api/v1/groups/`, {
                    params: {
                        'board_id': window.location.pathname.replace(/\/$/, '').split('/').pop()
                    },
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    }).then((response) => {
                        if (response.status === 200) {
                            setCanViewGroup(true);
                            if (response.data.length !== 0) {
                                setGroup(response.data);
                            }
                            else {
                                setGroup([]);
                            }
                        }
                    }).catch(error => {
                        if (error.response.status !== 403) {
                        }
                    });
            fetchAllUsers();
    };
    useEffect(() => {
        updateall()
        // eslint-disable-next-line
    }, []);

    function dragStartHandler(e, task, group) {
        setCurrenttask(task);
        setCurrentGroup([]);
        // const elem = document.getElementById("div-with-title-task");
        // elem.style.pointerEvents='none'
    };

    function dragStartHandlerGroup(e, group) {
        setCurrentGroup(group);
        setCurrenttask([]);
        // const elem = document.getElementById("div-with-title-task");
        // elem.style.pointerEvents='none'
    };

    function fetchAllUsers(page = 1, allUsers = []) {
        api.get(`${apiUrl}api/identity/users/`, {
            params: { page: page },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        })
        .then((response) => {
            if (response.status === 200) {
                const fetchedUsers = response.data;
                const updatedUsersList = allUsers.concat(fetchedUsers);
                if (response.data.next) {
                    fetchAllUsers(page + 1, updatedUsersList);
                } else {
                    setperformer(updatedUsersList);
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    function dragLeaveHandler(e) {
        e.target.style.boxShadow = 'none';
        if (e.target.id === 'detailtask') {
            e.target.style.boxShadow = '0px 4px 4px 0px rgba(0, 0, 0, 0.25)';
            e.target.style.marginTop = '0px';
            e.target.style.marginBottom = '0px';
        }
        if (e.target.id === 'drop-for-task' ) {
            e.target.style.boxShadow = 'none';
            e.target.style.height = '10px';
        }
        if (e.target.id === 'drop-for-group-div') {
            e.target.style.width = '20px'
            e.target.style.boxShadow = 'none';
        }
    };

    function dragEndHandler(e) {
        e.target.style.boxShadow = 'none';
        if (e.target.id === 'detailtask' ) {
            e.target.style.boxShadow = '0px 4px 4px 0px rgba(0, 0, 0, 0.25)';
            e.target.style.marginTop = '0px';
            e.target.style.marginBottom = '0px';
        }
        if (e.target.id === 'drop-for-task' ) {
            e.target.style.boxShadow = 'none';
            e.target.style.height = '10px';
        }
        if (e.target.id === 'drop-for-group-div') {
            e.target.style.width = '10px';
            e.target.style.boxShadow = 'none';
        }
        setCurrenttask([]);
        setCurrentGroup([]);
    };
    
    function dragOverHandler(e, task) {
        e.preventDefault();
        if (currenttask.length !== 0) {
            if (e.target.id === 'detailtask') {
                if (task) {
                    if (task !== currenttask) {
                        setTaskForTask(task);
                    }
                }
            }
            if (e.target.id === 'drop-for-task') {
                if (task) {
                    console.log(task)
                    if (task !== currenttask) {
                        setTaskForTask(task);
                        e.target.style.height = '100px';
                    }
                }
            }
        }
        if (currentgroup.length !== 0) {
            if (task) {
                if (task.parent === currentgroup.group_id) {

                }
                else if (currentgroup === task) {
                    
                }
                else if(e.target.id === 'drop-for-group-div') {
                    e.target.style.width = `200px`;
                    e.target.style.boxShadow = '0px 0px 0px 10px rgb(111, 99, 208, 1)';
                }
            }
        }
    };

    function dragDropHandlerForGroup(e, task, group){
        e.preventDefault();
        if (currentgroup.length === 0) {
            api.patch(`${apiUrl}api/v1/tasks/${currenttask.task_id}/update-group/`, {
                'group': group.group_id,
                'parent': null,
                'child': null
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    updateall();
                }
            }).catch(error =>{
                console.error(error);
            });
        }
        setCurrenttask([]);
    };

    function dragDropHandlerGroup(e, group) {
        e.preventDefault();
        if (group === currentgroup) {

        }
        else if (group.parent === currentgroup.group_id)
        {

        }
        else {
            if (currenttask.length === 0)
            {
                api.patch(`${apiUrl}api/v1/groups/${currentgroup.group_id}/`, {
                    'group': group.group_id
                },{
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        setGroup([]);
                        updateall();
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        }
        if (e.target.id === 'drop-for-group-div') {
            e.target.style.width = `20px`;
            e.target.style.boxShadow = 'none';
        }
        setCurrentGroup([]);
    };

    function dragDropHandler(e, task, group) {
        e.preventDefault();
        if (currenttask === task)
        {
                
        }
        else{
            if (currenttask.group === group.group_id){
                if (currenttask.parent === task.parent) {

                }
                if (currenttask.task_id === task.parent) {

                }
                else {
                    if (currentgroup.length === 0) {
                        api.patch(`${apiUrl}api/v1/tasks/${currenttask.task_id}/`, {
                            'task': task.task_id
                        },{
                            headers: {
                                'Authorization': 'Bearer ' + token
                            },
                        }).then((response) => {
                            if (response.status === 200) {
                                updateall();
                            }
                        }).catch(error => {
                            console.error(error);
                        });
                        updateall();
                        task = [];
                    }
                }
            } else {
                if (currentgroup.length === 0) {
                    api.patch(`${apiUrl}api/v1/tasks/${currenttask.task_id}/`, {
                        'group': task.group,
                        'task': task.task_id
                    },{
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                    }).then((response) => {
                        if (response.status === 200) {
                            console.log("currenttask was been updated")
                            updateall();
                        }
                    }).catch(error =>{
                        console.error(error);
                    });
                }
            }
        }
        if (e.target.id === 'detailtask') {
            e.target.style.boxShadow = '0px 4px 4px 0px rgba(0, 0, 0, 0.25)';
            e.target.style.marginTop = '0px';
            e.target.style.marginBottom = '0px';
        }
        if (e.target.id === 'drop-for-task') {
            e.target.style.boxShadow = 'none';
            e.target.style.height = '10px';
        }
        setCurrenttask([])
        // updateall();
    };

    var screenHeight = window.innerHeight;

    //але мега бог сортировок
    const buildTaskChains = (tasks) => {
        if (sortedDateTask === true) {
            const sortedTasks = tasks.sort((a, b) => {
                const dateA = a.deadline ? new Date(a.deadline) : Infinity;
                const dateB = b.deadline ? new Date(b.deadline) : Infinity;
                return dateA - dateB;
            });
            return sortedTasks
        }
        else {
            // Создаем мапу задач по их id для быстрого доступа
            const taskMap = new Map();
            tasks.forEach(task => taskMap.set(task.task_id, task));
        
            // Создаем массив для хранения корневых задач (у которых parent пуст)
            const roots = [];
        
            // Проходим по задачам и создаем цепочки
            tasks.forEach(task => {
                if (!task.parent) {
                    roots.push(task);
                } else {
                    const parentTask = taskMap.get(task.parent);
                    if (parentTask) {
                        if (!parentTask.children) {
                            parentTask.children = [];
                        }
                        parentTask.children.push(task);
                    }
                }
            });
        
            // Функция для создания плоского списка задач из дерева задач, останавливаясь если нет дочерних задач
            const flattenTasks = (task) => {
                let result = [task];
                while (task.children && task.children.length > 0) {
                    task = task.children[0]; // Берем первого ребенка
                    result.push(task);
                }
                return result;
            };
        
            // Объединяем все цепочки в один список
            let sortedTasks = [];
            roots.forEach(root => {
                sortedTasks = sortedTasks.concat(flattenTasks(root));
            });
        
            return sortedTasks;
        }
    };

    const handleFilterTaskOnDate = (e) => {
        e.preventDefault();
        setSortedDateTask(!sortedDateTask);
    };

    document.title = "Моя доска - ТвояЗадача";

    return (
        <div
            style={{ overflowX: 'auto',overflowY: 'hidden', width: "100%", height: screenHeight - 137, justifyContent: "space-between", alignItems: "center", display: "flex"}} 
            className="detail-board-page"
        >
                <div id="for-scroll" style={{display: 'flex', width: "100%", height: "100%", marginLeft: "50px"}}>
                <button id="sort-date-column-task"
                    onClick={(e) => handleFilterTaskOnDate(e)}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Сортировка по истечению срока сдачи"
                >
                    &#128197;
                </button>
                <Modal className="Modalforcreategroup"
                    isOpen={modalIsOpenGroup}
                    onRequestClose={closeModal}
                    overlayClassName="Overlayforcreategroup">
                        <div id="info-group">
                            <div style={{display: 'flex'}}>
                            <select id="post-select-status"
                                value={selectedSystemStatus}
                                onChange={handleChangeSelectSystemStatus}
                            >
                                {systemStatus.map((system, index) => (
                                    <option key={index} value={system.id}>{system.name}</option>
                                ))}
                            </select>
                                {/* <label class="checkbox style-e">
                                    Завершающее состояние группы
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleSetTrueGroup()}
                                        checked={selectedGroup.status}
                                    />
                                    <div class="checkbox__checkmark"></div>

                                </label> */}
                                <label id="hint-for-group-settings">
                                    Свойство определяющее статус задач в данной колонке.
                                </label>
                            </div>
                            <button id="CloseModalSystemStatus" onClick={closeModal}>X</button>
                            <button id="access-delete-group"
                                onClick={() => handleDeleteGroup()}
                            >
                                Удалить
                            </button>
                        </div>
                </Modal>
                <Modal className="Modalforcreategroup"
                    isOpen={modalIsOpen3}
                    onRequestClose={closeModal}
                    overlayClassName="Overlayforcreategroup">
                    <form onSubmit={handleSubmitCreateGroup} className="modalformforgroup">
                        <div style={{overflow: 'hidden'}}>
                            <input
                                type="text"
                                id="titlegroupinput"
                                placeholder='Наименование колонки'
                                required
                                onChange={handledColumnChange}
                            >
                            </input>
                            <select id="post-select-status" style={{marginLeft: "20px"}}
                                value={selectedSystemStatus}
                                onChange={handleChangeSelectSystemStatusForNewGroup}
                            >
                                {systemStatus.map((system, index) => (
                                    <option key={index} value={system.id}>{system.name}</option>
                                ))}
                            </select>
                            <label id="hint-for-group-settings" style={{marginLeft: "20px"}}>
                                Свойство определяющее статус задач в данной колонке.
                            </label>
                            <button id="ModalSubmitgroup" type="submit">Создать</button>
                        </div>
                        <button id="CloseModalCreateGroup2" onClick={closeModal}>X</button>
                    </form>
                </Modal>
                <div id="divwithgroup">
                {group.map((group, index) => (
                <div id="div-for-animated-group-draggable">
                    <div id="group" key={index}>
                        {canViewGroup ?
                        <div id="groupinput" 
                            draggable={true}
                            onDragStart={(e) => dragStartHandlerGroup(e, group)}
                            // onDragLeave={(e) => dragLeaveHandler(e)}
                            // onDragEnd={(e) => dragEndHandler(e)}
                            style={{display: "flex", border: "2px solid black", borderRadius: "10px",}}>
                            {canChangeGroup ?
                            <textarea 
                                type="text" 
                                id="titlelabel"
                                className="no-resize"
                                maxLength={50}
                                onClick={handleTextareaClick}
                                onChange={handleTextareachange}
                                onBlur={() => handleTextAreaBlur({title: group.title, group_id: group.group_id})}
                            >
                                {group.title}
                            </textarea>:
                            <textarea
                                type="text" 
                                id="titlelabel"
                                readOnly='false'
                                className="no-resize"
                                maxLength={50}
                                onClick={handleTextareaClick}
                            >
                                {group.title}
                            </textarea>}
                            <button id="openaccessdeletegroup"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Настройки группы"
                                onClick={() => handleopenaccessdeletegroup({groupid: group.group_id, status: group.status, system_status: group.system_status})}>
                                    &equiv;
                            </button>
                        </div>: null}
                        {canViewTasks ?
                            task.filter(task => task.group === group.group_id).length ?
                            <div id="divwiththegrouptasks"
                                onDragOver={(e) => dragOverHandler(e)}
                                // onDrop={(e) => dragDropHandlerForGroup(e, null, group)}
                            >
                            {buildTaskChains(task.filter(task => task.group === group.group_id)).map((task, taskIndex) => (
                            <div id="divforbuttuoncloseandbuttonopen" key={taskIndex}>
                                <div id="drop-for-task" index={taskIndex}
                                    onDragLeave={(e) => dragLeaveHandler(e)}
                                    onDragEnd={(e) => dragEndHandler(e)}
                                    onDragOver={(e) => dragOverHandler(e, task)}
                                    onDrop={(e) => dragDropHandler(e, task, group)}
                                >
                                </div>
                                {task.priority <= 3 ?
                                    <button id="detailtask" style={{borderLeft: `11px Solid rgb(3, 221, 11)`}}
                                    draggable={true}
                                    onClick={() => handleOpendetailTasks({taskid: task.task_id})}
                                    onMouseDown={(e) => handleOpendetailTasksNewPin(e, {taskid: task.task_id})}
                                    onDragStart={(e) => dragStartHandler(e, task, group)}
                                    onDragLeave={(e) => dragLeaveHandler(e)}
                                    onDragEnd={(e) => dragEndHandler(e)}
                                    onDragOver={(e) => dragOverHandler(e, task)}
                                    onDrop={(e) => dragDropHandler(e, task, group)}
                                >
                                    <div id="div-with-title-task"
                                        //  onDragOver={(e) => dragOverHandler(e)}
                                    >
                                        {task.title}
                                        {task.file.length > 0 || task.count_comments > 0 ?
                                        <div id="icon-right-for-task">
                                            {task.file.length > 0 ?
                                                <div id="task-file-length">
                                                    <img id="icon-for-right-task"
                                                        alt=""
                                                        src={apiUrlFrontend + "paperclip.svg"}
                                                    >
                                                    </img>
                                                    {task.file.length}
                                                </div>: null
                                            }
                                            {task.count_comments > 0 ? 
                                                <div id="task-file-length">
                                                    <img id="icon-for-right-task"
                                                        alt=""
                                                        src={apiUrlFrontend + "comment.svg"}
                                                    >
                                                    </img> {task.count_comments}
                                                </div>: null
                                            }
                                        </div>: null}
                                    </div>
                                     <div id="helthdiv">
                                         <div id="divforinfo">
                                             <div id="div-main-info-task">
                                             <div id="div-for-performer-column-image">
                                             {(performer.filter(pf => task.performer.includes(pf.username) && pf.image).map((k, kindex) => {
                                                     return (
                                                         <img
                                                             key={kindex}
                                                             id="userphoto"
                                                             src={k.image}
                                                             alt=""
                                                         />
                                                     )
                                             })).length === 0 ? 
                                             <img
                                                 id="user-template-photo"
                                                 src={apiUrlFrontend + "icon_man.png"}
                                                 alt=""
                                                 // eslint-disable-next-line
                                             />: (performer.filter(pf => task.performer.includes(pf.username) && pf.image).map((k, kindex) => {
                                                        if (kindex === 0) {
                                                            return (
                                                                 <img
                                                                     key={kindex}
                                                                     id="userphoto"
                                                                     src={k.image}
                                                                     alt=""
                                                                 />
                                                             )
                                                         }
                                                         else if (kindex === 1) {
                                                             return (
                                                                  <img
                                                                      key={kindex}
                                                                      id="userphoto"
                                                                      src={k.image}
                                                                      alt=""
                                                                  />
                                                              )
                                                          }
                                                          else if (kindex === 2) {
                                                             return (
                                                                  <img
                                                                      key={kindex}
                                                                      id="userphoto"
                                                                      src={k.image}
                                                                      alt=""
                                                                  />
                                                              )
                                                          }
                                                          else if (kindex === 3) {
                                                            return (
                                                                 <label>...</label>
                                                             )
                                                         }
                                                        }))}
                                                        </div>
                                             {task.label.map(lab => lab).slice(0, 3).length ?
                                             <div id="divforlabel">
                                                 {task.label.map(lab => lab).slice(0, 3).map((label, labelIndex) => (
                                                     <div key={labelIndex} id="divforlabeltitle">
                                                         <div id="circle"
                                                             style={{background: label.color}}
                                                         />
                                                         <div id="label-name-color">
                                                             {label.name.slice(0, 15)}
                                                         </div>
                                                     </div>
                                                 ))}
                                             </div>: null}
                                             {task.label.map(lab => lab).slice(3, 6).length ?
                                                 <div id="divforlabel">
                                                 {task.label.map(lab => lab).slice(3, 6).map((label, labelIndex) => (
                                                     <div key={labelIndex} id="divforlabeltitle">
                                                         <div id="circle"
                                                             style={{background: label.color}}
                                                         />
                                                         <div id="label-name-color">
                                                             {label.name.slice(0, 15)}
                                                         </div>
                                                     </div>
                                                 ))}
                                                 </div>: null}
                                             </div>
                                         </div>
                                     </div>
                                     {task.deadline ?
                                     <div 
                                        style={{display: 'flex', color: ((new Date(task.deadline) - new Date()) / 86400000) <= 3 ? "rgb(175, 52, 52)": ((new Date(task.deadline) - new Date()) / 86400000) <= 7 ? 'rgba(220, 136, 0, 1.00)': 'rgba(100, 120, 214, 1.00)', fontSize: "16px"}}
                                     >
                                        {format(new Date(task.deadline), 'dd-MM-yyyy')}
                                        {((new Date(task.deadline) - new Date()) / 86400000) < -0.9 && group.system_status_name !== 'Completed' ?
                                        <div id="overdue-deadline">
                                            <img id="icon-for-right-task"
                                                alt=""
                                                src={apiUrlFrontend + "overdue-task-deadline.svg"}
                                            >
                                            </img> {Math.floor(((new Date(task.deadline) - new Date()) / 86400000))}д.
                                        </div>: null}
                                     </div>: null}
                                    </button>
                                 : task.priority > 3 && task.priority <= 7 ?
                        <button id="detailtask" style={{borderLeft: `11px Solid rgb(255, 229, 0)`}}
                            draggable={true} 
                            onClick={() => handleOpendetailTasks({taskid: task.task_id})}
                            onMouseDown={(e) => handleOpendetailTasksNewPin(e, {taskid: task.task_id})}
                            onDragStart={(e) => dragStartHandler(e, task, group)}
                            onDragLeave={(e) => dragLeaveHandler(e)}
                            onDragEnd={(e) => dragEndHandler(e)}
                            onDragOver={(e) => dragOverHandler(e, task)}
                            onDrop={(e) => dragDropHandler(e, task, group)}
                        >
                            <div id="div-with-title-task"
                                // onDragOver={(e) => dragOverHandler(e)}
                            >
                                {task.title}
                                {task.file.length > 0 || task.count_comments > 0 ?
                                        <div id="icon-right-for-task">
                                            {task.file.length > 0 ?
                                                <div id="task-file-length">
                                                    <img id="icon-for-right-task"
                                                        alt=""
                                                        src={apiUrlFrontend + "paperclip.svg"}
                                                    >
                                                    </img>
                                                    {task.file.length}
                                                </div>: null
                                            }
                                            {task.count_comments > 0 ? 
                                                <div id="task-file-length">
                                                    <img id="icon-for-right-task"
                                                        alt=""
                                                        src={apiUrlFrontend + "comment.svg"}
                                                    >
                                                    </img> {task.count_comments}
                                                </div>: null
                                            }
                                        </div>: null}
                            </div>
                            <div id="helthdiv">
                                <div id="divforinfo">
                                    <div id="div-main-info-task">
                                    <div id="div-for-performer-column-image">
                                    {(performer.filter(pf => task.performer.includes(pf.username) && pf.image).map((k, kindex) => {
                                            return(
                                                <img
                                                    key={kindex}
                                                    id="userphoto"
                                                    src={k.image}
                                                    alt=""
                                                />
                                            )
                                    })).length === 0 ? 
                                    <img
                                        id="user-template-photo"
                                        src={apiUrlFrontend + "icon_man.png"}
                                        alt=""
                                        // eslint-disable-next-line
                                    />: (performer.filter(pf => task.performer.includes(pf.username) && pf.image).map((k, kindex) => {
                                            if (kindex === 0) {
                                               return (
                                                    <img
                                                        key={kindex}
                                                        id="userphoto"
                                                        src={k.image}
                                                        alt=""
                                                    />
                                                )
                                            }
                                            else if (kindex === 1) {
                                                return (
                                                     <img
                                                         key={kindex}
                                                         id="userphoto"
                                                         src={k.image}
                                                         alt=""
                                                     />
                                                 )
                                             }
                                             else if (kindex === 2) {
                                                return (
                                                     <img
                                                         key={kindex}
                                                         id="userphoto"
                                                         src={k.image}
                                                         alt=""
                                                     />
                                                 )
                                             }
                                             else if (kindex === 3) {
                                                return (
                                                     <label>...</label>
                                                 )
                                             }
                                            }))}
                                            </div>
                                    {task.label.map(lab => lab).slice(0, 3).length ?
                                    <div id="divforlabel">
                                        {task.label.map(lab => lab).slice(0, 3).map((label, labelIndex) => (
                                            <div key={labelIndex} id="divforlabeltitle">
                                                <div id="circle"
                                                    style={{background: label.color}}
                                                />
                                                <div id="label-name-color">
                                                    {label.name.slice(0, 15)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>: null}
                                    {task.label.map(lab => lab).slice(3, 6).length ?
                                        <div id="divforlabel">
                                        {task.label.map(lab => lab).slice(3, 6).map((label, labelIndex) => (
                                            <div key={labelIndex} id="divforlabeltitle">
                                                <div id="circle"
                                                    style={{background: label.color}}
                                                />
                                                <div id="label-name-color">
                                                    {label.name.slice(0, 15)}
                                                </div>
                                            </div>
                                        ))}
                                        </div>: null}
                                    </div>
                                </div>
                            </div>
                            {task.deadline ? 
                            <div 
                                style={{display: 'flex', color: ((new Date(task.deadline) - new Date()) / 86400000) <= 3 ? "rgb(175, 52, 52)": ((new Date(task.deadline) - new Date())/ 86400000) <= 7 ? 'rgba(220, 136, 0, 1.00)': 'rgba(100, 120, 214, 1.00)', fontSize: '16px'}}
                            >
                                {format(new Date(task.deadline), 'dd-MM-yyyy')}
                                {((new Date(task.deadline) - new Date()) / 86400000) < -0.9 && group.system_status_name !== 'Completed' ?
                                <div id="overdue-deadline">
                                <img id="icon-for-right-task"
                                    alt=""
                                    src={apiUrlFrontend + "overdue-task-deadline.svg"}
                                >
                                </img> {Math.floor(((new Date(task.deadline) - new Date()) / 86400000))}д.
                            </div>: null}
                            </div>: null
                            }
                        </button>:
                        <button id="detailtask" style={{borderLeft: `11px Solid rgb(244, 0, 0)`}}
                            draggable={true} 
                            onClick={() => handleOpendetailTasks({taskid: task.task_id})}
                            onMouseDown={(e) => handleOpendetailTasksNewPin(e, {taskid: task.task_id})}
                            onDragStart={(e) => dragStartHandler(e, task, group)}
                            onDragLeave={(e) => dragLeaveHandler(e)}
                            onDragEnd={(e) => dragEndHandler(e)}
                            onDragOver={(e) => dragOverHandler(e, task)}
                            onDrop={(e) => dragDropHandler(e, task, group)}
                        >
                        <div id="div-with-title-task"
                            // onDragOver={(e) => dragOverHandler(e)}
                        >
                            {task.title}
                            {task.file.length > 0 || task.count_comments > 0 ?
                                        <div id="icon-right-for-task">
                                            {task.file.length > 0 ?
                                                <div id="task-file-length">
                                                    <img id="icon-for-right-task"
                                                        alt=""
                                                        src={apiUrlFrontend + "paperclip.svg"}
                                                    >
                                                    </img>
                                                    {task.file.length}
                                                </div>: null
                                            }
                                            {task.count_comments > 0 ? 
                                                <div id="task-file-length">
                                                    <img id="icon-for-right-task"
                                                        alt=""
                                                        src={apiUrlFrontend + "comment.svg"}
                                                    >
                                                    </img> {task.count_comments}
                                                </div>: null
                                            }
                                        </div>: null}
                        </div>
                        <div id="helthdiv">
                            <div id="divforinfo">
                                <div id="div-main-info-task">
                                    <div id="div-for-performer-column-image">
                                    {(performer.filter(pf => task.performer.includes(pf.username) && pf.image).map((k, kindex) => {
                                        return (
                                            <img
                                                key={kindex}
                                                id="userphoto"
                                                src={k.image}
                                                alt=""
                                            />
                                        )
                                    })).length === 0 ? 
                                <img
                                    id="user-template-photo"
                                    src={apiUrlFrontend + "icon_man.png"}
                                    alt=""
                                    // eslint-disable-next-line
                                />: (performer.filter(pf => task.performer.includes(pf.username) && pf.image).map((k, kindex) => {
                                    if (kindex === 0) {
                                        return (
                                             <img
                                                 key={kindex}
                                                 id="userphoto"
                                                 src={k.image}
                                                 alt=""
                                             />
                                         )
                                     }
                                     else if (kindex === 1) {
                                         return (
                                              <img
                                                  key={kindex}
                                                  id="userphoto"
                                                  src={k.image}
                                                  alt=""
                                              />
                                          )
                                      }
                                      else if (kindex === 2) {
                                         return (
                                              <img
                                                  key={kindex}
                                                  id="userphoto"
                                                  src={k.image}
                                                  alt=""
                                              />
                                          )
                                      }
                                      else if (kindex === 3) {
                                        return (
                                             <label>...</label>
                                         )
                                     }
                                    }))}
                                    </div>
                                {task.label.map(lab => lab).slice(0, 3).length ?
                                <div id="divforlabel">
                                    {task.label.map(lab => lab).slice(0, 3).map((label, labelIndex) => (
                                        <div key={labelIndex} id="divforlabeltitle">
                                            <div id="circle"
                                                style={{background: label.color}}
                                            />
                                            <div id="label-name-color">
                                                {label.name.slice(0, 15)}
                                            </div>
                                        </div>
                                    ))}
                                </div>: null}
                                {task.label.map(lab => lab).slice(3, 6).length ?
                                    <div id="divforlabel">
                                    {task.label.map(lab => lab).slice(3, 6).map((label, labelIndex) => (
                                        <div key={labelIndex} id="divforlabeltitle">
                                            <div id="circle"
                                                style={{background: label.color}}
                                            />
                                            <div id="label-name-color">
                                                {label.name.slice(0, 15)}
                                            </div>
                                        </div>
                                    ))}
                                    </div>: null}
                                </div>
                            </div>
                        </div>
                        {task.deadline ?
                        <div 
                            style={{display: 'flex', color: ((new Date(task.deadline) - new Date()) / 86400000) <= 3 ? "rgb(175, 52, 52)": ((new Date(task.deadline) - new Date()) / 86400000) <= 7 ? 'rgba(220, 136, 0, 1.00)': 'rgba(100, 120, 214, 1.00)', fontSize: '16px'}}
                        >
                            {format(new Date(task.deadline), 'dd-MM-yyyy')}
                            {((new Date(task.deadline) - new Date()) / 86400000) < -0.9 && group.system_status_name !== 'Completed' ?
                            <div id="overdue-deadline">
                            <img id="icon-for-right-task"
                                alt=""
                                src={apiUrlFrontend + "overdue-task-deadline.svg"}
                            >
                            </img> {Math.floor(((new Date(task.deadline) - new Date()) / 86400000))}д.
                        </div>: null}
                        </div>: null
                        }
                    </button>}
                            </div>
                ))}
                </div>:
                        <div id="divwiththegrouptasks"
                                onDragOver={(e) => dragOverHandler(e)}
                                onDrop={(e) => dragDropHandlerForGroup(e, null, group)}
                            >
                            </div>
                            : null}
                    {canAddTask ?
                        <button 
                            onClick={() => openModal({grouptitle: group.title, group_id: group.group_id, groupsystem_status: group.system_status})}
                            onMouseDown={(e) => openModalNewPin(e, {grouptitle: group.title, group_id: group.group_id, groupsystem_status: group.system_status})}
                            id="addtask"
                            // onDragOver={(e) => dragOverHandler(e)}
                            // onDrop={(e) => dragDropHandler(e, null, group)}
                        >
                            Добавить задачу
                        </button>: null}
                    </div>
                        <div id="drop-for-group-div"
                            onDragOver={(e) => dragOverHandler(e, group)}
                            onDragEnd={(e) => dragEndHandler(e)}
                            onDrop={(e) => dragDropHandlerGroup(e, group)}
                            onDragLeave={(e) => dragLeaveHandler(e)}
                        >
                        </div>
                </div>
                ))}
                    {canAddGroup ? 
                        <button id="createnewgroup" onClick={handlecreategroup}>
                            Добавьте еще одну колонку
                        </button>: null}
                </div>
            </div>
            <Tooltip 
                id="my-tooltip"
                delayShow={750}
                delayHide={100}
            />
        </div>
       
    );
}

export default DetailBoard;