import React, { useState } from 'react';
import './styles/Footer.css';
import axios from 'axios';

const ChangePassword = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const handlePasswordChangeConfirm = (event) => {
        setPasswordConfirm(event.target.value);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (password !== passwordConfirm) {
            alert("Пароли не совпадают!");
        }
        else {
            const wordsArray = window.location.href.split('/');
            const [penultimateWord, lastWord] = wordsArray.slice(-2);
            axios.post(`${apiUrl}api/identity/auth/users/reset_password_confirm/`, {
                uid: penultimateWord,
                token: lastWord,
                new_password: password,
                re_new_password: passwordConfirm,
            })
            .then(response => {
                localStorage.setItem('token', '');
                localStorage.setItem('refreshToken', '');
                window.location.href = "/login";
            })
            .catch(error =>{
            const status = error.response ? error.response.status: null;
            if (error.response.status === 400)
                {
                    alert(`Код ошибки: ${status}, ${error.response.data.new_password}`, error);
                }
                else{
                    alert(`Ошибка: ${error.code}, Код ошибки: ${status}`, error);
                }
            });
        }
    };

    var screenHeight = window.innerHeight;
    document.title = "Смена пароля - ТвояЗадача";

    return (
        <div className="login-page" style={{height: screenHeight-140, width: "100%"}}>
        <form onSubmit={handleSubmit} className="login-form">
            <h2>Смена пароля</h2>
            <div className="form-group">
            <input
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder='Введите новый пароль' 
                required
            />
            </div>
            <input
                type="password"
                id="password"
                value={passwordConfirm}
                onChange={handlePasswordChangeConfirm}
                placeholder='Подвердите пароль' 
                required
            />
            <button id="change-password-btn" type="submit">Сменить пароль</button>
        </form>
    </div>
  );
}

export default ChangePassword;