import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Header from './pages/Header';
import { BrowserRouter as Router} from 'react-router-dom';
import Footer from './pages/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Header/>
    </Router>
    <App />
    <Router>
      <Footer/>
    </Router>
  </React.StrictMode>
);
reportWebVitals();
