const PrivacyPolicy = () => {
    return (
        <div>
            <div id="translatetriangle6">
                <div className="triangle6"></div>
            </div>
            <div id="translatetriangle">
                <div className="triangle"></div>
            </div>
            <div id="translatetriangle2">
                <div className="triangle2"></div>
            </div>
            <div id="translatetriangle3">
                <div className="triangle3"></div>
            </div>
            <div id="translatestar">
                <div className="triangle4"></div>
            </div>
            <div id="translatestar2">
                <div className="triangle5"></div>
            </div>
            <div id="translatestar3">
                <div className="triangle7"></div>
            </div>
            <div id="translatestar4">
                <div className="triangle8"></div>
            </div>
            <div id="pomegranaterectangle"></div>
            <div id="bluerectangle"></div>
        <h1 id='txt'> Это PrivacyPolicy</h1>
        </div>
    );
}

export default PrivacyPolicy;