import React, { useState, useEffect } from 'react';
import './styles/ChoiceOrganization.css';
import api from '/app/frontend/src/api';

const ChoiceOrganization = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const [user, setUser] = useState([]);
    const [user_id, setUser_id] = useState('');
    var screenHeight = window.innerHeight;

    const handleClickChangeOrganization = (organization) => {
        api.patch(`${apiUrl}api/identity/users/${user_id}/update-organization/`, {
            'organization': organization
        },{
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.detail === 'update') {
                    window.location.href = '/main'
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const updateAll = () => {
        api.get(`${apiUrl}api/identity/auth/users/me/`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        }).then((response) => {
            if (response.status === 200) {
                setUser(response.data.available_organization || []);
                setUser_id(response.data.id)
                if (response.data.available_organization.length === 1) {
                    window.location.href = '/main'
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    useEffect(() => {
        updateAll();
        // eslint-disable-next-line
    }, []);

    document.title = "Выбор организации - ТвояЗадача";

    return (
        <div className="choice-organization-page" style={{ maxHeight: screenHeight - 140, width: "100%", justifyContent: "center" }}>
            <div id="translatetriangle6">
                <div className="triangle6"></div>
            </div>
            <div id="translatetriangle">
                <div className="triangle"></div>
            </div>
            <div id="translatetriangle2">
                <div className="triangle2"></div>
            </div>
            <div id="translatetriangle3">
                <div className="triangle3"></div>
            </div>
            <div id="translatestar">
                <div className="triangle4"></div>
            </div>
            <div id="translatestar2">
                <div className="triangle5"></div>
            </div>
            <div id="translatestar3">
                <div className="triangle7"></div>
            </div>
            <div id="translatestar4">
                <div className="triangle8"></div>
            </div>
            <div id="pomegranaterectangle"></div>
            <div id="bluerectangle"></div> 
            <div id="div-for-choice-organization">
                <label id="label-choice-organization">
                    Выберите организацию
                </label>
                {user.length > 0 ? user.map((organization, index) => (
                    <button id="button-choice-organization" key={index} onClick={() => handleClickChangeOrganization(organization)}>
                        {organization}
                    </button>
                )) : null}
            </div>
        </div>
    );
}

export default ChoiceOrganization;