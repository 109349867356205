import React, { useState } from 'react';
import './styles/HelloPage.css'
import axios from 'axios';

const MainPage = () => {
  const [nameOrganization, setNameOrganization] = useState('');
  const [mail, setMail] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
  const handlenameOrganizationChange = (event) => {
    setNameOrganization(event.target.value);
  };
  const handlemailChange = (event) => {
    setMail(event.target.value);
  };
  const handlephoneNumberChange = (event) => {
    setphoneNumber(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post('', {
      name_organization: nameOrganization,
      mail: mail,
      phone_number: phoneNumber
    })
    .then(response => {
      window.location.href = "/"
    })
    .catch(error =>{
      
    });
  };
  return (
    <div className="hello-page">
                <div id="main-txt-div">
                  <text id="mainText">Управляй временем.</text>
                  <text id="miniText">Интеллектуальная система управления задачами, которая функционирует как ваш персональный организатор, оптимизируя эффективность и удобство ведения дел.</text>
                    <div id="main-down-div">
                      <div className="square-support">  
                        <img id="technical-support-gif"
                          src={apiUrlFrontend + '/technical-support.gif'}
                          alt=""
                        >
                        </img>
                        <text id="txt-support">
                          Ежедневная Техническая Поддержка
                        </text>
                      </div>
                      <img id="left-arrow"
                        src={apiUrlFrontend + 'left-arrow.png'}
                        alt=''
                      >
                      </img>  
                      <form onSubmit={handleSubmit} className="application-form">
                        <div className="form-group">
                          <h2 id="maintextinform">Оставьте заявку на подключение</h2>
                          <label id="nameOrganizationlabel">Название организации:</label>
                          <input
                            type="text"
                            id="nameOrganization"
                            value={nameOrganization}
                            onChange={handlenameOrganizationChange} 
                            required
                          />
                          <label id="maillabel">Ваш почтовый адрес:</label>
                          <input
                            type="mail"
                            id="mail"
                            value={mail}
                            onChange={handlemailChange}
                            required
                          />
                          <label id="phoneNumberlabel">Номер телефона:</label>
                          <input
                            type="phoneNumber"
                            id="phoneNumber"
                            value={phoneNumber}
                            onChange={handlephoneNumberChange}
                            required
                          />
                        </div>
                          <button id="send" type="submit">Отправить</button>
                      </form>
                      <img id="right-arrow"
                          src={apiUrlFrontend + 'right-arrow.png'}
                          alt=''
                      >
                      </img>
                      <div className="square-fast-solution">
                        <img id="fast-solutions-gif"
                          src={apiUrlFrontend + '/fast-solutions-task.gif'}
                          alt=""
                        >
                        </img>     
                        <text id="txt-fast-solution">
                          Быстрое Решение Задач
                        </text>
                      </div>
                </div>
              </div>
    </div>
  );
};

export default MainPage;
