import React, { useState } from 'react';
import './styles/Main.css';
import Modal from 'react-modal';
import api from '/app/frontend/src/api';
import { Tooltip } from 'react-tooltip';

const Main = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const [user, setUser] = useState('');
    const [board, setBoard] = useState([]);
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [privatboard, setPrivateBoard] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [modalSetBoard, setIsOpenSetBoard] = useState(false);
    const [selectedBoard, setSelectedBoard] = useState([]);
    const [checkpermission, setCheckPerm] = useState(false);
    const [checkrole, setCheckRole] = useState(false);
    const [addrole, setAddRole] = useState(false);
    const [canCreateBoard, setCanCreateBoard] = useState(false);
    const [canChangeBoard, setCanChangeBoard] = useState(false);
    const [canViewBoard, setCanViewBoard] = useState(false);
    const [canViewUser, setCanViewUser] = useState(false);
    const [notificationBoard, setNotificationBoard] = useState(false);
    const [notificationBoardsTrue, setNotificationBoardsTrue] = useState([]);
    const [canSeeHiddenBoards, setCanSeeHiddenBoards] = useState(true);
    const [hiddenBoards, setHiddenBoards] = useState([]);
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;

    const handleTitleChange = (event) => {
        if (event.target.value.length <= 50) {
            setTitle(event.target.value);
        } 
        else{
            return;
        }
    };
    const handleSetPrivate = () => {
        if (privatboard === true){
            setPrivateBoard(false);
        }
        else {
            if (selectedUser.length !== 0) {
                alert("Вы не можете сделать доску приватной, пока в ней находится пользователи!");
            }
            else{
                setPrivateBoard(true);
            }
        }
    };
    const handleSlugChange = (event) => {
        if (event.target.value.length <= 50) {
            setSlug(event.target.value);
        } else {
            return;
        }
    };

    function closeModal() {
        setIsOpen(false);
        setIsOpenSetBoard(false);
        setTitle("");
        setSlug("");
        setPrivateBoard(false);
        setUsers([]);
        setNotificationBoard(false);
    };

    function openModal() {
        setIsOpen(true)
    };

    const handleGoToProfile = (event) => {
        window.location.href = "/profile/" + user.username
    };
    const handleGoToProfileNewPin = (e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/profile/" + user.username);
        }
    };
    const handleGoToSettings = (event) => {
        window.location.href = "/settings"
    };
    const handleGoToSettingsNewPin = (e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/settings");
        }
    };
    const handleGoToTechnicalSupport = (event) => {
        window.location.href = "/main-info"  
    };
    const handleGoToTechnicalSupportNewPin = (e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/main-info");
        }
    };
    const handleClick = (slug, title, id, e) => {
        window.location.href = "/boards/" + slug;
        localStorage.setItem('titleboards', title);
        localStorage.setItem('slugboards', slug);
        localStorage.setItem('boardID', id);
    }
    const handleClickNewTab = (slug, title, id, e) => {
        localStorage.setItem('titleboards', title);
        localStorage.setItem('slugboards', slug);
        localStorage.setItem('boardID', id);
        if (e.button === 1) {
            e.preventDefault();
            window.open("/boards/" + slug);
        }
    }
    const handleClickAuthor = (author, e) => {
        window.location.href = "/profile/" + author;
    };
    const handleMouseDownAuthor = (author, e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/profile/" + author);
        }
    };

    React.useEffect(() => {
        const loadBoards = async () => {
            if (loading || !hasMore) return;
            setLoading(true);
            try {
                const response = await api.get(`${apiUrl}api/v1/boards/`, {
                params: { page: page },
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            if (response.status === 200) {
                setBoard(prevBoards => {
                    const newBoards = response.data.results.filter(newBoard => 
                    !prevBoards.some(prevBoard => prevBoard.id === newBoard.id));
                    return [...prevBoards, ...newBoards];
                });
                setCanViewBoard(true);
                setHasMore(response.data.next != null);
            }} 
            catch (error) {
                console.error(error)
                const status = error.response ? error.response.status: null;
                if (status !== 403) {
                }
            }
            await api.get(`${apiUrl}api/v1/user/permissions/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    for (const item in response.data) {
                        if (response.data[item] === 'Can view group') {
                            setCheckRole(true);
                        }
                        if (response.data[item] === 'Can add group') {
                            setAddRole(true);
                        }
                        if (response.data[item] === 'Can view permission') {
                            setCheckPerm(true);
                        }
                        if (response.data[item] === 'Can change Доска') {
                            setCanChangeBoard(true);
                        }
                        if (response.data[item] === 'Can view Пользователь') {
                            setCanViewUser(true);
                        }
                        if (response.data[item] === 'Can add Доска') {
                            setCanCreateBoard(true);
                        }
                        else{
                            setPrivateBoard(true);
                        }
                        if (response.data.includes('owner_organization')){
                            setCheckRole(true);
                            setAddRole(true);
                            setCheckPerm(true);
                            setCanChangeBoard(true);
                            setCanViewUser(true);
                            setCanCreateBoard(true);
                        }
                    }
                }
            }).catch(error => {
                console.log(error);
            });
            setLoading(false);
        };
        loadBoards();
        // eslint-disable-next-line
    }, [page]);
      
    React.useEffect(() => {
        const handleScroll = (event) => {
            const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
            if (scrollHeight - scrollTop <= clientHeight * 1.5 && !loading) {
                setPage(prevPage => prevPage + 1);
            }
        };
        const divRef = document.getElementById('divboarduser');
        divRef.addEventListener('scroll', handleScroll);
        return () => divRef.removeEventListener('scroll', handleScroll);
    }, [loading]);

    React.useEffect(() => {
        api.get(`${apiUrl}api/identity/auth/users/me/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setHiddenBoards(response.data.hidden_boards)
                setUser(response.data);
            }
        }).catch(error =>{
            console.error(error);
        });
        // eslint-disable-next-line
    }, [token]);

    const handleClickOpenCloseAccess = (data) => {
        if (data.item.private_board === false) {
            const loadBoards = async () => {
                await api.get(`${apiUrl}api/identity/users/`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        setUsers(response.data);
                        setSelectedUser(response.data.filter(user => 
                            data.item.users.includes(user.id)).map(user=>user.id))
                    }
                }).catch(error => {
                    console.error(error);
                });
            }
            loadBoards();
        }
        api.get(`${apiUrl}api/identity/auth/users/me/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setNotificationBoardsTrue(response.data.notification_from_board)
                    if (response.data.notification_from_board.includes(data.item.id)) {
                        setNotificationBoard(true);
                    }
                }
            }).catch(error => {
                console.error(error);
            });;
        setIsOpenSetBoard(true);
        setIsOpen(true);
        setSelectedBoard(data);
        setPrivateBoard(data.item.private_board);
        setTitle(data.item.title);
        setSlug(data.item.slug);
        
    };

    const handleSubmitPostBoards = (event) => {
        event.preventDefault();
        if (modalSetBoard === true) {
            // if (selectedBoard.item.slug === slug && selectedBoard.item.title === title && selectedBoard.item.private_board === privatboard && selectedUser.filter(user=>selectedBoard.item.users.includes(user.id)))
            // {
            //     alert("НЕ ТЫКАЙ ПРОСТО ТАК")
            // }
           // else{
                api.patch(`${apiUrl}api/v1/boards/${selectedBoard.item.slug}/`, {
                    'title': title,
                    'slug': slug,
                    'private_board': privatboard,
                    'users': selectedUser,
                },{
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        setSelectedUser([])
                        closeModal();
                        alert("Успешно обновленно");
                        window.location.href = "/main";
                    }
                }).catch(error =>{
                    alert(error);
                });
           // }
        }
        else {
            api.post(`${apiUrl}api/v1/boards/`, {
                'title': title,
                'slug': slug,
                'private_board': privatboard,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 201) {
                    closeModal();
                    alert("Успешно добавлено");
                    localStorage.setItem('slugboards', response.data.slug);
                    localStorage.setItem('boardID', response.data.id);
                    window.location.href = `/main`;
                }
            }).catch(error =>{
                console.log(error.response.status)
                if (error.response.status === 400) {
                    if (error.response.data.slug) {
                        alert("Указан неверный идентификатор страницы URL");
                    }
                }
            });
        }
    };

    const handleAccessDelete = (event) => {
        const result = window.confirm("ты подумай сейчас ок нажмешь и все удалится");
        if (result) {
            api.delete(`${apiUrl}api/v1/boards/${selectedBoard.item.slug}/`,{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 204) {
                    closeModal();
                    alert("Успешно удалено");
                    window.location.href = "/main";
                }
            })
            .catch(error =>{
                console.error(error);
            });
        }
    };

    const handleSetTruRole = (data) => {
        let newSelectedRole = [...selectedUser];
        if (newSelectedRole.includes(data.id)) {
            newSelectedRole = newSelectedRole.filter(item => item !== data.id);
        } 
        else {
            newSelectedRole.push(data.id);
        }
        setSelectedUser(newSelectedRole);
        console.log(newSelectedRole);
    };

    const handleChangeNotificationBoard = (id_board) => {
        setNotificationBoard(!notificationBoard)
        if (notificationBoardsTrue.includes(id_board)) {
            let updatedNotifications = notificationBoardsTrue.filter(item => item !== id_board);
            setNotificationBoardsTrue(updatedNotifications);
            api.patch(`${apiUrl}api/identity/auth/users/me/`, {
                'notification_from_board': updatedNotifications,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) { 
                    // обработка успешного ответа
                }
            }).catch(error => {
                console.error(error)
            });
        }
        else {
            let updatedNotifications = [...notificationBoardsTrue, id_board]
            setNotificationBoardsTrue([...notificationBoardsTrue, id_board]);
            api.patch(`${apiUrl}api/identity/auth/users/me/`, {
                'notification_from_board': updatedNotifications,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                }
            }).catch(error => {
                console.error(error)
            });
        }
    };

    const handleClickHideBoard = (event, item) => {
        if (hiddenBoards.includes(item)) {
            const updatedHiddenBoards = hiddenBoards.filter(board => board !== item);
            setHiddenBoards(updatedHiddenBoards);
            api.patch(`${apiUrl}api/identity/auth/users/me/`, {
                'hidden_boards': updatedHiddenBoards,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    alert("Доска убрана из скрытых")
                }
            }).catch(error => {
                console.error(error);
            });
        }
        else {
            let updatedHiddenBoards = [...hiddenBoards, item]
            setHiddenBoards([...hiddenBoards, item]);
            api.patch(`${apiUrl}api/identity/auth/users/me/`, {
                'hidden_boards': updatedHiddenBoards,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setBoard((prevBoards) => prevBoards.filter(board => board.id !== item));
                    alert("Доска скрыта");
                }
            }).catch(error => {
                console.error(error)
            });
        }
    };

    const handleClickShowHiddenBoards = (event) => {
        event.preventDefault();
        setCanSeeHiddenBoards(!canSeeHiddenBoards);
        if (canSeeHiddenBoards) {
            api.get(`${apiUrl}api/v1/boards/get-hidden-boards/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    const hidenboards = response.data;
    
                    setBoard((prevBoards) => {
                        const newHiddenBoards = hidenboards.filter(hiddenBoard => 
                            !prevBoards.some(board => board.id === hiddenBoard.id)
                        );
                        return [
                            ...newHiddenBoards,
                            ...prevBoards
                        ];
                    });
                }
            }).catch(error =>{
                console.error(error);
            });
        }
        else {
            setBoard((prevBoards) => prevBoards.filter(board => !hiddenBoards.includes(board.id)));
        }
    };

    document.title = "Мои доски - ТвояЗадача";

    var screenHeight = window.innerHeight;

    return (
        <div 
            style={{ overflow: 'hidden', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center'}} 
            className="main-page"
        >
            <Modal
                className="Modal"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <button id="CloseModal" onClick={closeModal}>X</button>
                <form id="board-form" onSubmit={handleSubmitPostBoards}>
                    {modalSetBoard === true ? 
                    <label id="ModalLabel">Настройки доски</label> :
                    <label id="ModalLabel">Создать доску</label> }
                    <div id="modalline"/>
                    <input
                        type="text"
                        id="modaltitle"
                        value={title}
                        onChange={handleTitleChange}
                        placeholder='Заголовок'
                        required
                    />
                    <input 
                        type="text"
                        id="slug"
                        value={slug}
                        onChange={handleSlugChange}
                        placeholder='Адрес страницы'
                        required
                    />
                    <label id="hint-for-create-board-slug">
                        Идентификатор страницы для URL,
                        разрешены символы латиницы, дефис и подчеркивание
                    </label>
                    {canCreateBoard ?
                    modalSetBoard === true ? 
                        selectedBoard.item.author === user.username ?
                            <div id="is-private-div">
                                <label id="label-private">Приватная доска</label>
                                <label class="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleSetPrivate({})}
                                        checked={privatboard}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>: null : <div id="is-private-div">
                                <label id="label-private">Приватная доска</label>
                                <label class="checkbox style-e">
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => setPrivateBoard(!privatboard)}
                                        checked={privatboard}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>
                    :  <div id="is-private-div">
                    <label id="label-private">Приватная доска</label>
                    <label class="checkbox style-e">
                        <input id="check-group"
                            type="checkbox"
                            checked={true}
                        />
                        <div class="checkbox__checkmark"></div>
                    </label>
                </div>}
                {modalSetBoard === true ? 
                    <div style={{display: 'flex'}}>
                        <label id="notification-this-board">
                            Получать уведомления с этой доски
                        </label>
                        <input id="status-notification-mail"
                            type="checkbox"
                            checked={notificationBoard}
                            onChange={() => handleChangeNotificationBoard(selectedBoard.item.id)}
                        >
                        </input>
                    </div>: null
                }
                {privatboard === false ?
                users.map((us, usindex) => (
                    <div id="checkbox-with-perm" index={usindex}>
                        {us.image ?
                            <img id="image-users"
                                src={us.image}
                                alt=''
                            />:
                            <img id="not-found-image-users"
                                src={apiUrlFrontend + "icon_man.png"}
                                alt=''
                            />}
                        <div id="name-per">
                            {us.formated_fio ?
                            <label id="nameuser">
                                {us.formated_fio.slice(0, 20)}
                            </label>:
                            <label id="nameuser">
                                {us.username.slice(0, 20)}
                            </label>
                            }
                        </div>
                        <label class="checkbox style-e">
                            <input id="check-group"
                                type="checkbox"
                                onChange={() => handleSetTruRole({id: us.id, group_id: selectedBoard.id})}
                                checked={selectedUser.includes(us.id)}
                            />
                            <div class="checkbox__checkmark"></div>
                        </label>
                    </div>
                )): null
                }
                    {modalSetBoard === true ? 
                        <div style={{display: 'flex'}}>
                            <button id="openAccessDelete" type="button" onClick={handleAccessDelete}>Удалить</button>
                            <button id="ModalSubmit" type="submit">Изменить</button>
                        </div> :
                        <button id="ModalSubmit" type="submit">Отправить</button>
                    }
                </form>
            </Modal>
            <div id="div-with-main-main">
            <div id="divboarduser" style={{maxHeight: screenHeight - 350}}>
                {board.length > 0 ? board.map((item, index) => (
                    <div id="divforboard" key={index}>
                        <button id="buttonfordiv"
                            onMouseDown={(e) => {handleClickNewTab(item.slug, item.title, item.id, e)}}
                            onClick={(e) => {handleClick(item.slug, item.title, item.id, e)}}>  
                            <div id="title">{item.title}</div>
                            {/* <div id="circleforboard"></div>
                            <div id="circleforboard2"></div> */}
                        </button>
                        {canChangeBoard || item.private_board === true ?
                        <button id="opencloseaccess"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Настройки доски"
                            onClick={() => {handleClickOpenCloseAccess({item})}}
                        >
                            &#9776;
                        </button>: null}
                        {hiddenBoards.includes(item.id) ?
                        <img id="show-board-button"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Показать доску"
                            src={apiUrlFrontend + "show-board.svg"}
                            alt=""
                            onClick={(event) => handleClickHideBoard(event, item.id)}
                        >
                        </img>: 
                        <img id="hide-board-button"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Скрыть доску"
                            src={apiUrlFrontend + "hide-board.svg"}
                            alt=""
                            onClick={(event) => handleClickHideBoard(event, item.id)}
                        >
                        </img>}
                        {canViewUser ?
                        item.formated_fio_author ?
                        <button id="author"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Профиль пользователя"
                            onMouseDown={(e) => {handleMouseDownAuthor(item.author, e)}}
                            onClick={(e) => {handleClickAuthor(item.author, e)}}>
                            {item.formated_fio_author}
                        </button>: 
                        <button id="author"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Профиль пользователя"
                            onMouseDown={(e) => {handleMouseDownAuthor(item.author, e)}}
                            onClick={(e) => {handleClickAuthor(item.author, e)}}>
                            {item.author}
                        </button>
                        : null}
                    </div>
                )): canViewBoard === false ? 
                <div id="null-tasks">Нет прав для просмотра досок, обратитесь к администратору.</div>:
                <div id="null-tasks">Досок нет, время создавать</div>}
            </div>
                <div id="boards-main-button-div">
                    <button onClick={openModal} id="createboards">
                        Создать доску
                    </button>
                    <button id="show-hidden-boards" onClick={(event) => handleClickShowHiddenBoards(event)}>
                        Cкрытые доски
                    </button>
                </div>
            </div>
            <Tooltip 
                id="my-tooltip"
                delayShow={750}
                delayHide={100}
            />
        </div>
    );
}

export default Main;