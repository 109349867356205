import React from 'react';
import './styles/Footer.css'
import { Link } from "react-router-dom"

const Footer = () => {
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    return (
        <footer className="footer">
            <hr id="linegorizontal"/>
            <div id="main-footer">
                <div id="div-with-logo">
                    <img id="developer-logo"
                        src={apiUrlFrontend + "logo-developer.ico"}
                        alt=''
                    >
                    </img>
                    <img id="support-company-logo"
                        src={apiUrlFrontend + "logo-support-company.svg"}
                        alt=''
                    >
                    </img>
                </div>
                <div id="div-with-footer-link">
                    <Link onClick={() => window.location.href= '/create-appeal'} id="appeal">Написать обращение</Link>
                    <hr id="linevertical"/>
                    <Link onClick={() => window.location.href= '/about'} id="info">О нас</Link>
                    <hr id="linevertical"/>
                    <Link onClick={() => window.location.href= '/privacy-policy'} id="privacypolicy">Политика конфиденциальности</Link>
                </div>
                <div id="EAC">
                <Link onClick={() => window.location.href= '/'} relative="path" id="project-name">Твоя</Link>
                <Link onClick={() => window.location.href= '/'} relative="path" id="companyNameT">З</Link>
                <Link onClick={() => window.location.href= '/'} relative="path" id="project-nameAsk">адача</Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;