import React, { useState } from 'react';
import './styles/Registration.css'
import axios from 'axios';

const Registration = () => {
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const apiUrl = process.env.REACT_APP_API_URL;
    const secretKey = process.env.REACT_APP_INVITE_KEY
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [nameOrganization, setNameOrganization] = useState("");
    const [registrationKey, setRegistrationKey] = useState("");
    const [email, setEmail] = useState("");
    const [telegramId, setTelegramId] = useState("");
    const [checkBot, setCheckBot] = useState(1);
    
    var screenHeight = window.innerHeight;
    
    document.title = "Регистрация - ТвояЗадача";

    const handleSubmit = (event) => {
        event.preventDefault();
        if (checkBot > 2) {
            window.location.href = "/"
        }
        else {
            // console.log(secretKey)
            // console.log(registrationKey)
            if (secretKey === registrationKey) {
                axios.post(`${apiUrl}api/identity/users/registration/`, {
                    name_organization: nameOrganization,
                    email: email,
                    password: password,
                    // telegramId: telegramId,
                    username: login,
                    invite_pass: registrationKey,
                })
            .then(response => {
                localStorage.setItem('token', response.data.access_token);
                localStorage.setItem('refreshToken', response.data.refresh_token)
                window.location.href = "/choice-organization"
            })
            .catch(error =>{
                const status = error.response ? error.response.status: null;
                    if (error.response.status === 400) {
                        if (error.response.data.email) {
                            alert(`Код ошибки: ${status}, ${error.response.data.email}`, error);
                        }
                        if (error.response.data.error) {
                            alert(`Код ошибки: ${status}, ${error.response.data.error}`, error);
                        }
                    }
            });
            }
            else {
                alert("Введен неверный код приглашения")
                setCheckBot(checkBot + 1)
            }
        }
      };

    return (
        <div style={{height: screenHeight-136}} 
            className="registration-page"
        >
            <form className="registration-form"
                onSubmit={handleSubmit}
            >
                <h1><label id="registration-label">Регистрация</label></h1>
                <div style={{display: "flex"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <input
                            type="text"
                            id="registration-username-txt"
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                            placeholder='Логин'
                            required
                        />
                        <input
                            type="password"
                            id="registration-password-txt"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Пароль' 
                            required
                        />
                        <input
                            type="text"
                            id="registration-email-txt"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Электронная почта' 
                            required
                        />
                        <input
                            type="text"
                            id="registration-organization-name"
                            value={nameOrganization}
                            onChange={(e) => setNameOrganization(e.target.value)}
                            placeholder='Имя организации' 
                            required
                        />
                        <input
                            type="password"
                            id="registration-key"
                            value={registrationKey}
                            onChange={(e) => setRegistrationKey(e.target.value)}
                            placeholder='Код приглашения' 
                            required
                        />
                    </div>
                    <div style={{display: "flex", flexDirection: "column", marginLeft: "20px", justifyContent: "center"}}>
                        {/* <button id="registration-telegram-accept">Привязать телеграм</button>
                        <button id="registration-telegram-accept">Регистрация через телеграм</button> */}
                        <button id="registration-confirm" type="submit">Зарегистрироваться</button>
                    </div>
                </div>
                {/* <div id="div-tg-btn">
                    <TelegramLoginButton id="tgbutton" buttonSize="small" dataOnauth={handleTelegramResponse} botName={telegramBotName}/>
                </div> */}
            </form>
        </div>
    );
}

export default Registration;